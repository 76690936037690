import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import CTA1 from "../components/CTA1";
import Specialhero from "../components/hero/Specialhero";

function SRI() {
  return (
    <Layout>
      <Specialhero />
      <Container>
        <div id="tabs-container">
          <div id="sidebar">
            <ul>
              <li>
                <Link href="#Termite Inspection">Termite Inspections</Link>
              </li>
              <li>
                <Link href="#Mold Inspection">Mold Inspections</Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Termite Inspections & Mold Inspections</h3>
          <p>
            Young Home Inspections has been providing quality specialized
            inspections for over 30 years. We provide superior Mold Inspections,
            Termite Inspections as well as inspections for other Wood Destroying
            Organisms (WDO). Do not trust your largest investment to just
            anyone. Make sure your inspector is properly licensed and insured.
            You can trust our highly-trained, certified and insured experts will
            provide high-quality reports and the greatest expertise.
          </p>
        </div>
        <h3 id="Termite Inspection">Termite/ WDO Inspection</h3>
        <p>
          Many people shopping for homes are concerned about the overall
          condition of a home. Here in Florida termites are a constant threat
          and worry prospective home buyers. To relieve the doubt and worry of
          not knowing about structure damaging pest infestations, a termite or
          wood-destroying organisms (WDO) inspection report can be obtained.
          This is a required inspection for all VA and some FHA loans.
        </p>
      </Container>
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/termite.jpg" />
          </Col>
          <Col lg={6} className="fifty-split-1">
            <p>
              A WDO inspection report informs a prospective home buyer if a
              home:
            </p>
            <ul>
              <li>
                Has living termites or wood destroying organisms currently
                present
              </li>
              <li>
                Has evidence of an infestation of wood destroying organisms
              </li>
              <li>Has damage from wood destroying organisms</li>
              <li>Has been previously treated for wood destroying organisms</li>
            </ul>
            <p>
              In the report, the inspector is required to identify all WDOs by
              their common name as well as give the exact location of the
              evidence supporting their discovery. If for any reason an area(s)
              was not accessible at the time of inspection, the reason and
              location of the area(s) that were not accessible must also be
              stated in the termite inspection report.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Often times, a potential home buyer will see or hear the word
              “clear” used in or about a WDO report. This “clear” report
              basically states that there was no evidence of wood destroying
              organisms and no visible damage caused by them of any area(s)
              accessible when the building was inspected.
            </p>
            <p>
              It is extremely important to note that even with a “clear” termite
              inspection report, it is not impossible for there to be wood
              destroying organisms present or damage by such organisms behind
              walls and other places unavailable to view.
            </p>
            <p>
              A home buyer that is interested in purchasing a home should also
              get as much information and history possible about any previous
              WDO inspections and any previous termite treatments or contracts
              about a residence they wish to purchase. Once previous termite
              inspections or treatment history are obtained they should be
              thoroughly reviewed. A thorough review will likely provide a
              better determination if the home has been adequately protected for
              termite and other wood destroying organisms. This will give the
              prospective buyer the best possible outlook on the home in
              question.
            </p>
            <Link to="/request" className="btn btn-secondary mb-50">
              <p>Schedule Your Inspection Now</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <h3 id="Mold Inspection">Mold Inspection</h3>
            <p>
              Florida might be a tropical paradise, but it is also an extremely
              humid place to live. Because of this, mold is very common problem
              in the Sunshine State. Although all types of mold are unpleasant
              looking in a home, certain types of mold can make people very ill.
              This is especially true if you have an allergy to mold. This is
              why people are often concerned about the presence of mold in their
              home. If a homeowner wants to be relatively certain there is or is
              not mold present in their home they can get a Mold Inspection.
            </p>
            <p>
              A Mold Inspection consists of testing both the air and surfaces
              for the presence of mold spores. Samples are taken from both
              inside the home in areas suspected to contain mold as well as
              outside the home to determine if the mold is in fact coming from
              the inside of the home and hasn’t simply just traveled there from
              the outside.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <p>
              Samples are collected through various methods and then are sent to
              a lab for a comprehensive analysis. The sample collection methods
              range from swabbing a surface to collecting air through filters
              using vacuums.
            </p>
            <p>
              Areas around your home will be careful assessed and sampled. These
              include your carpets if applicable, various hard and porous
              surfaces, and the air in common areas as well as behind walls
              where mold can go undetected for long periods of time. There are
              many reasons why mold grows and can go for great lengths of time
              undetected behind walls and other areas hidden from plain view.
              These areas of mold are often a result of water damage or leaks.
              This is why it is important to obtain a Mold Inspection at the
              first suspicion that there may be a mold issue.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/mold2.jpg" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Because of the nature of mold growing in high moisture areas, this
              makes kitchens, bathrooms, as well as areas next to them prime
              targets for mold damage. As was mentioned previously mold tends to
              grow silently and out of sight for great lengths of time. This is
              why it is recommended to always obtain a Mold Inspection when
              having other types of home inspections such as a Pre-Purchase Home
              Inspection or a Pre-Sale Home Inspection.
            </p>
          </Col>
        </Row>
      </Container>
      <CTA1 />
    </Layout>
  );
}

export default SRI;
