import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CTA1 = () => {
  return (
    <div className="cta">
      <Container>
        <Row>
          <Col>
            <h4>
              Want to know the truth before you close? We are an impartial
              inspection company.
            </h4>
            <Link to="/contact" className="btn btn-secondary center-btn">
              Contact Us
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CTA1;
